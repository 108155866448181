import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PumpContainer } from './pump.container';
import { TabSelectorModule } from '../../components/tab-selector/tab-selector.module';
import { OverviewKpisModule } from '../../components/overview-kpis/overview-kpis.module';
import { CrossPlotModule } from '../../components/cross-plot/cross-plot.module';
import { PerformanceDriftModule } from '../../components/performance-drift/performance-drift.module';
import { EnergyPerformanceModule } from "../compressors/components/energy-performance/energy-performance.module";
import { OperationalParametersModule } from "../../components/operational-parameters/operational-parameters.module";
import { PiVisionLinkModule } from '../../components/pi-vision-link/pi-vision-link.module';
import { AiLoadDistributionModule } from "../../components/ai-load-distribution/ai-load-distribution.module";



const routes: Routes = [
  { path: '', component: PumpContainer }
];

@NgModule({
  declarations: [PumpContainer],
    imports: [
        CommonModule,
        FormsModule,
        TabSelectorModule,
        OverviewKpisModule,
        CrossPlotModule,
        PerformanceDriftModule,
        RouterModule.forChild(routes),
        EnergyPerformanceModule,
        OperationalParametersModule,
        PiVisionLinkModule,
        AiLoadDistributionModule
    ],
  exports: [PumpContainer]
})
export class PumpContainerModule { }
